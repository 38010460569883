<template>
  <div>
    <nav class="desktop-menu">
        <ul class="is-flex is-justify-content-flex-end">
            <li class="menu-item" v-for="(menuItem, i) in menuItems" :key="i">
                <a class="menu-item-link"
                   :class="[linkClass, i === menuItems.length-1 ? 'last': '']"
                   :href="menuItem.link"> {{menuItem.text}} </a>
            </li>
        </ul>
    </nav>
    <div class="mobile-menu-button">
        <b-icon
            :class="linkClass"
            icon="menu"
            size="is-large"
            @click.native="showNavPanel = true">
        </b-icon>
    </div>
    <b-sidebar
      type="is-primary"
      :fullheight="true"
      :fullwidth="false"
      :overlay="false"
      :right="true"
      v-model="showNavPanel"
    >

        <nav class="mobile-menu">
            <div class="has-text-right">
                <b-icon
                    class="has-text-white"
                    icon="close"
                    size="is-medium"
                    aria-label="close"
                    @click.native="showNavPanel = false">
                </b-icon>
            </div>
            <ul>
                <li class="menu-item mb-5" v-for="(menuItem, i) in menuItems" :key="i">
                    <a
                        @click="showNavPanel = false"
                        class="menu-item-link"
                        :class="[i === menuItems.length-1 ? 'last': '']"
                        :href="menuItem.link">{{menuItem.text}}
                    </a>
                </li>
            </ul>
        </nav>
    </b-sidebar>
  </div>
</template>

<script>
export default {
    name: "SiteMenu",
    props: {
        linkClass: {
            type: String,
            default: 'has-text-white'
        }
    },
    data: () => ({
        menuItems:[
            {text: 'About', link: 'http://depths.plumb.one/what-is-plumb'},
            {text: 'DoDI', link: 'http://depths.plumb.one/declaration-of-digital-independence-dodi'},
            {text: 'Privacy', link: 'http://depths.plumb.one/privacy-policy'},
            {text: 'Mobile', link: 'http://depths.plumb.one/mobile-app'},
            {text: 'VPN', link: 'http://depths.plumb.one/vpn'},
            {text: 'Blog', link: 'http://depths.plumb.one/blog'},
            {text: 'Submit URL', link: '/submit-url'},
        ],
        showNavPanel: false
    })
}
</script>

<style lang="scss" scoped>
    .menu-item-link{
        font-weight: 600;
        text-transform: uppercase;
    }
    .desktop-menu .menu-item-link{
        font-size: 16px;
        margin-right: 12px !important;
        &::after{
            margin-left: 12px !important;
            content: '|' !important; 
        }
        &.last{
            margin-right: 0 !important;
            &::after{
                margin-left: 0 !important;
                content: none !important;
            }
        }    
    }
    .mobile-menu-button{
        display: none;
        margin-right: 5px;
    }
    .mobile-menu{
        padding: 20px;
    }
    .mobile-menu .menu-item-link{
        font-size: 18px;
    }
    @media screen and (max-width:1600px){
        .desktop-menu{
            display: none;
        }
        .mobile-menu-button{
            display: block;
        }
    }
</style>