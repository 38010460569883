<template>
  <b-modal has-modal-card v-model="showModal">
    <div class="modal-card is-relative">
      <div class="modal-card-body content">
        <b-icon
          class="has-text-black"
          icon="close"
          size="is-medium"
          aria-label="close"
          @click.native="showModal = false"
          style="position: absolute; top: 15px; right: 15px; cursor: pointer"
        >
        </b-icon>
        <p class="title is-4">{{title}}</p>

        <div v-if="success" class="mb-5">
          <b-notification class="mb-5" type="is-success">
            Thanks! Your message has been sent. 
          </b-notification>

          <div class="buttons is-justify-content-center">
            <b-button @click="showModal = false">Close</b-button>
          </div>
        </div>

        <b-notification class="mb-5" type="is-danger" v-if="error">
          {{ error }}
        </b-notification>

        <div v-if="!success">
            <p>
            {{ text }}
            </p>
            <form v-on:submit.prevent="submitForm" style="max-width: 650px">
            <b-field label="Email">
                <b-input
                v-model="email"
                size="is-medium"
                expanded
                placeholder="Your email"
                type="email"
                required
                ></b-input>
            </b-field>
            <b-field label="Message">
                <b-input v-model="message" style="min-height:7em" placeholder="Your message..." maxlength="400" type="textarea" :required="messageRequired"></b-input>
            </b-field>
            <b-field>
                <b-checkbox v-model="optin"
                >Add me to the {{ siteName }} email list to get the latest
                updates</b-checkbox
                >
            </b-field>
            <b-button size="is-medium" expanded class="mt-6" type="is-primary" native-type="submit">
                Send
            </b-button>
            
            <input style="display:none" v-model="botcheck" name="botcheck">

            </form>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import axios from "axios";

const ERROR_CODES = {
  UNDEFINED:
    "There was an error processing your request, please check the form for errors and try again.",
};

export default {
  name: "ContactModal",
  props: {
    modalActive: {
      type: Boolean,
      default: false,
    },
    title:{
      type: String,
      default: "Contact Us"
    },
    text:{
      type: String,
      default: ""
    },
    formName:{
      type: String,
      default: "contact"
    },
    messageRequired:{
      type: Boolean,
      default: false
    },
    optInChecked:{
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    siteName: process.env.VUE_APP_SITE_NAME,
    botcheck: null,
    email: null,
    message: null,
    optin: false,
    success: false,
    error: null,
    ERROR_CODES: ERROR_CODES,
  }),
  computed: {
    showModal: {
      get() {
        return this.modalActive;
      },
      set() {
        this.$emit("close");
      },
    },
  },
  watch:{
      modalActive(val){
          if(val === true){
              this.reset();
          }
      }
  },
  methods: {
    reset(){
        this.botcheck = null;
        this.email = null;
        this.message = null;
        this.optin = this.optInChecked;
        this.success = false;
        this.error = null;
    },
    async submitForm() {
      const url = process.env.VUE_APP_EMAIL_SUBMIT_URL;

      this.success = false;
      this.error = null;

      let result;
      let data = {
        email: this.email,
        message: this.message,
        optin: this.optin,
        botcheck: this.botcheck,
        formName: this.formName
      };

      try {
        result = await axios.post(url, data);

        if (result.data && result.data.error) {
          let message =
            this.ERROR_CODES[result.data.error] || this.ERROR_CODES.UNDEFINED;
          throw message;
        } else {
          this.success = true;
        }
      } catch (err) {
        console.error(err)
        this.error = this.ERROR_CODES.UNDEFINED;
      }
    },
  },
  mounted(){
    this.optin = this.optInChecked;
  }
};
</script>

<style>
</style>