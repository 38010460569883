<template>
  <div id="app">
    <top-bar v-if="!isHomePage" />
    <site-menu v-else class="top-site-menu" />
    <router-view style="min-height: 800px" />
    <site-footer />

    <contact-modal
      title="Send Feedback"
      :text="`Thanks for using ${ siteName }! Have an idea that will make it better
            or spotted a bug? Let us know using the form below.`"
      form-name="feedback" 
      :modal-active="showFeedback" 
      @close="showFeedback=false"
      message-required
    ></contact-modal>

    <contact-modal
      title="Join Us"
      :text="`Become a ${ siteName } insider and we'll keep you in the loop. Share your thoughts in the form below and be sure to check the box to get future updates.`"
      form-name="join" 
      :modal-active="showJoinUs"
      opt-in-checked 
      @close="showJoinUs=false"  
    ></contact-modal>    

  </div>
</template>


<script>
import TopBar from '@/components/TopBar.vue';
import SiteMenu from '@/components/SiteMenu.vue';
import SiteFooter from '@/components/SiteFooter.vue';
import ContactModal from "@/components/ContactModal";


export default {
  components: {
    TopBar, SiteMenu, SiteFooter, ContactModal
  },
  computed: {
    isHomePage(){
      return this.$route.name === 'Home'
    }
  },
  data:() => ({
    showFeedback: false,
    showJoinUs: false,
    siteName: process.env.VUE_APP_SITE_NAME,
  }),
  mounted(){
    this.$eventBus.$on('scrollToTop', () => {
      this.$scrollTo('body');
    });
    this.$eventBus.$on('showFeedback', () => {
      this.showFeedback = true;
    });
    this.$eventBus.$on('showJoinUs', () => {
      this.showJoinUs = true;
    });

    if(window.location.href.indexOf('form=feedback') > -1){
      this.showFeedback = true;
    }
    if(window.location.href.indexOf('form=join-us') > -1){
      this.showJoinUs = true;
    }
  }
}
</script>

<style lang="scss" scoped>
.top-site-menu{
  position: absolute;
  top: 40px;
  right: 40px;
  z-index: 10;
}
@media screen and (max-width: 768px){
  .top-site-menu{
    top: 20px;
    right: 20px;
  }
}
</style>