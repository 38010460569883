<template>
  <div>
    <section class="blade search-input-section has-text-centered">
      <div class="full-size-bg has-background-blue-3">
        <picture role="presentation">
          <source srcset="/images/octo-bg-2.webp" type="image/webp" />
          <source srcset="/images/octo-bg-2.png" type="image/jpeg" />
          <img class="full-size-bg-img" src="/images/octo-bg-2.png" />
        </picture>
      </div>

      <div class="ceph-logo">
        <img
          class="logo"
          src="/images/CEPH_Icon.png"
          alt="Plumb.one"
          width="125"
          height="125"
        />
      </div>

      <div
        class="
          search-input-content
          is-flex is-align-items-center is-flex is-justify-content-center
        "
      >
        <div class="content">
          <img class="logo mb-2" src="/images/plumb-logo.svg" alt="Plumb.one" />
          <div class>
            <a
              class="has-text-white is-size-5"
              style="text-decoration: underline"
              href="#"
              @click.prevent="$eventBus.$emit('showFeedback')"
              >Get our emails</a
            >
          </div>
          <search-input class="is-block search-input" />
          <h1 class="has-text-white mb-0">Independent Search.</h1>
          <h2 class="has-text-white m-0">See more. Stay free.</h2>
          <external-link
            class="unstoppable-banner is-block mt-4"
            :href="$settings.UNSTOPPABLE_LINK"
            target="_blank"
            ><img
              width="200"
              :src="require('@/assets/images/PLUMB_Unstoppable_light.png')"
              alt="Unstoppable Domains"
          /></external-link>
        </div>
      </div>

      <div @click="$scrollTo('#why-plumb')" class="more-arrow-container">
        <down-arrows></down-arrows>
      </div>
    </section>

    <div class="content">
      <section id="why-plumb" class="has-background-blue-5 has-text-blue-1">
        <div class="container py-6">
          <div class="columns is-centered">
            <div class="column is-7-desktop">
              <h2 class="has-text-white">Why Plumb</h2>

              <p>Broader search results and complete privacy.</p>

              <p>
                Our own index enables us to provide results from more of the
                internet, so you’ll find things you won't find elsewhere.
              </p>

              <p>
                Complete privacy because we think it’s in everyone’s best
                interest and that we can provide great results without
                surveilling the rest of your life.
              </p>

              <p class="has-text-bold">Plumb.one - Independent Search</p>

              <p>
                <a
                  class="has-text-white has-text-bold has-underline"
                  href="#"
                  @click.prevent="$eventBus.$emit('showJoinUs')"
                  >Join us</a
                >
              </p>

              <p>
                <router-link
                  class="has-text-bold has-underline has-text-white"
                  to="/about"
                  >More about Plumb</router-link
                >
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import SearchInput from "@/components/SearchInput";
import DownArrows from "@/components/DownArrows";

export default {
  name: "Home",
  components: { SearchInput, DownArrows },
  data: () => ({}),
};
</script>

<style lang="scss" scoped>
.unstoppable-banner {
  img {
    width: 300px;
  }
  position: absolute;
  right: 38px;
  top: 90px;
  @media screen and (max-width: 1000px) {
    position: relative;
    top: 0;
    left: 0;
  }
}
.search-input-section {
  height: 100vh;
  min-height: 660px;
  position: relative;
  @media screen and (max-width: 1000px) {
    min-height: 835px;
  }
}

.ceph-logo {
  z-index: 2;
  top: 40px;
  position: relative;
  @media (hover: hover) {
    position: absolute;
    left: 40px;
  }
}

.search-input-content {
  z-index: 2;
  height: 100%;
  position: relative;
  @media not all and (hover: hover) {
    bottom: 60px;
  }
  & > .content {
    width: 100%;
    padding-left: 30px;
    padding-right: 30px;
    h1 {
      font-weight: 800;
      font-size: 1.5em;
    }
    h2 {
      font-weight: 800;
      font-size: 1.5em;
    }
    p {
      font-size: 17px;
      margin-top: 2.8em;
    }

    @media not all and (hover: hover) and (min-device-height: 700px) {
      h1 {
        font-weight: 600;
        font-size: 1.1em;
        margin-bottom: 1px;
      }
      h2 {
        font-weight: 600;
        font-size: 1.1em;
        margin-bottom: 1px;
      }
      p {
        font-size: 10px;
        margin-top: 1em;
      }
    }
  }
}

.search-input {
  width: 100%;
  max-width: 560px;
  margin: 25px auto 50px;

  @media not all and (hover: hover) and (min-device-height: 700px) {
    margin-bottom: 30px;
  }
}

.more-arrow-container {
  position: absolute;
  bottom: 0;
  height: 40px;
  width: 100%;
  z-index: 2;
  cursor: pointer;
  &:hover .more-arrow {
    transform: scale(1.5);
  }
}
</style>
