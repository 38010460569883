<template>
    <nav v-scroll="handleScroll" role="banner" :class="{'is-minimal': isMinimal}" class="navbar is-fixed-top top-bar has-background-blue-5 is-align-items-center">
        <router-link to="/"><img class="logo" src="/images/plumb-logo.svg" alt="Plumb.one"></router-link>
        <search-input class="is-block search-input" />
        <site-menu link-class="has-text-primary" class="top-site-menu" />
    </nav>
</template>

<script>
import SearchInput from "@/components/SearchInput";
import SiteMenu from "@/components/SiteMenu";
export default {
    name: "TopBar",
    components: { SearchInput, SiteMenu },
    data: () => ({
        isMinimal: false
    }),
    methods: {
        handleScroll(evt, el) {
            if (window.scrollY > 80) {
                this.isMinimal = true;
            }else{
                this.isMinimal = false;
            }
            return false;
        }
    },    
}
</script>

<style lang="scss" scoped>
    //@import '@/assets/scss/_vars.scss';
    .logo{
        width: 223px;
        max-width: 223px;
        height: auto;
        position: relative;
        top: 5px;
        padding-right: 50px;
    }
    .search-input{
        width: 616px;
        padding-right: 30px;
    }
    .top-bar{
        padding: 40px;
        display: flex;
        background-image: url('/images/header-bg.png');
        background-repeat: no-repeat;
        background-size: auto 100%;
        box-shadow: 1px 1px 5px 2px rgba(0,0,0,0);
        transition: all 200ms ease-out;
        &.is-minimal{
            box-shadow: 1px 1px 5px 2px rgba(0,0,0,0.4);
            padding-top: 10px;
            padding-bottom: 10px;
        }
    }
    .top-site-menu{
        margin-left: auto;
    }
    @media screen and (max-width: 768px){
        .top-bar{
            padding: 30px;
            display: block;
        }
        .search-input{
            width: 100%;
        }
        .top-site-menu{
            position: absolute;
            top: 20px;
            right: 20px;
        }
        .logo{
            display: block;
            width: 170px;
            margin-bottom: 28px;
        }
        .logo, .search-input{
            padding-right: 0;
        }
    }
</style>