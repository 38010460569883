<template>
  <footer class="footer content has-text-centered">
    <div class="m-3">
      <div>&copy; {{ year }} {{ siteName }}</div>
    </div>

    <div class="m-3">
      <router-link :to="{ name: 'About' }">About</router-link>
    </div>

    <div class="m-3">
      <router-link :to="{ name: 'PrivacyPolicy' }">Privacy Policy</router-link>
    </div>

    <div class="m-3">
      <router-link :to="{ name: 'SubmitUrl' }">Submit URL</router-link>
    </div>

    <div class="m-3">
      <a href="#" @click.prevent="$eventBus.$emit('showFeedback')">Send feedback</a>
    </div>
    
  </footer>
</template>

<script>
export default {
  name: "SiteFooter",
  data: () => ({
    year: new Date().getFullYear(),
    siteName: process.env.VUE_APP_SITE_TITLE,
  }),
};
</script>

<style lang="scss" scoped>
footer{
    display: flex;
    justify-content: center;
    @media screen and (max-width: 800px){
        display: block;
    }
}
</style>