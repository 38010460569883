<template>
  <!-- @submit.prevent="submit" -->
  <form :action="formAction">
    <b-field>
      <b-input
        v-model="query"
        size="is-medium"
        expanded
        name="q"
        placeholder="Search..."
        :icon-right="this.query ? `close-circle`: ``"
        icon-right-clickable
        @icon-right-click="clearIconClick"
        autocomplete="off"
      ></b-input>

      <p class="control">
        <b-button
          size="is-medium is-hidden-desktop"
          class="search-button"
          native-type="submit"
          type="is-primary"
          icon-right="magnify"
        />
        <b-button
          size="is-medium is-hidden-touch"
          class="search-button"
          native-type="submit"
          type="is-primary"
          label="FIND"
        />
      </p>
    </b-field>
  </form>
</template>

<script>
export default {
  name: "SearchInput",
  data: () => ({
    query: null,
  }),
  methods: {
      clearIconClick(){
          this.query = null;
      }
    /*
        submit(){
            let searchType = this.$route.params.searchType || null;
            let query = this.query;
            //Queryless searching could be possible by omitting the query object below
            this.$router.push({ name: 'Results', params: { query, searchType }, query: { q: query } })
            //this.$router.push(`/results/${searchType}?q=${query}`)
        }
        */
  },
  watch: {
    $route() {
      if (this.$route.query.q) {
        this.query = this.$route.query.q;
      }
    },
  },
  computed: {
    formAction() {
      let searchType = this.$route.params.searchType || "";
      return `/results/${searchType}`;
    },

    /*query:{
            get(){
                return this.$store.state.query;
            },
            set(value){
                this.$store.commit('setQuery', value);
            }
        },*/
  },
};
</script>

<style lang="scss" scoped>
.search-button {
  font-weight: 600;
  letter-spacing: 2px;
}
</style>