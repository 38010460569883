import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import moment from 'moment'
import lang from './lang'
import ExternalLink from './components/ExternalLink'
import settings from './settings'
import VueMatomo from 'vue-matomo';

import Buefy from 'buefy'
import '@mdi/font/css/materialdesignicons.css'
import '@/assets/scss/style.scss'
Vue.use(Buefy, {
  defaultInputAutocomplete: 'off'
})

import LoadScript from 'vue-plugin-load-script';
Vue.use(LoadScript);

import VueScrollTo from 'vue-scrollto';
Vue.use(VueScrollTo)

Vue.config.productionTip = false
Vue.prototype.$eventBus = new Vue();
Vue.prototype.$lang = lang;

/* Filters */
Vue.filter('trimDesc', function (text) {
  return text.substring(0,200);;
});

Vue.filter('trimTitle', function (text) {
  return text.substring(0,128);;
});

Vue.filter('dateTimeFormat', function (date) {
  return moment(date).format('lll');
});

Vue.filter('durationFormat', function (seconds) {
  let hms = moment().startOf('day')
          .seconds(seconds)
          .format('H:m:s').split(':');
  let result = `${hms[2]}s`;
  if(hms[1] > 0) result = `${hms[1]}m ${result}`;
  if(hms[0] > 0) result = `${hms[0]}h ${result}`;     
  return result;   

});

Vue.directive('scroll', {
  inserted: function (el, binding) {
    let f = function (evt) {
      if (binding.value(evt, el)) {
        window.removeEventListener('scroll', f)
      }
    }
    window.addEventListener('scroll', f)
  }
})

Vue.prototype.$settings = settings;
Vue.component('ExternalLink', ExternalLink);

Vue.use(VueMatomo, {
  host: "https://plumb.one/analytics/",
  siteId: 1,
  trackerFileName: 'matomo',
  router: router,
  enableLinkTracking: true,
  requireConsent: false,
  trackInitialView: true,
  disableCookies: false,
  enableHeartBeatTimer: false,
  heartBeatTimerInterval: 15,
  debug: false,
  userId: undefined,
  cookieDomain: undefined,
  domains: undefined,
  preInitActions: []
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
